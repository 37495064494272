import React from 'react';
// COMPONENTS
import { Loader } from 'rsuite';
// INTERFACES
import { ExLoaderProps } from './ExLoader.interfaces';
// STYLES
import styles from './ExLoader.module.css';

const ExLoader = ({ size }: ExLoaderProps): JSX.Element => {
    return <Loader size={size} className={styles['loader']} />;
};

export default ExLoader;
