// interface
import { IDragDropUploaderHeader } from './DragDropUploaderHeader.interface';
// rsuite
import { IconButton } from 'rsuite';
//import CloseIcon from '@rsuite/icons/Close';
//mui
import CloseIcon from '@mui/icons-material/Close';
// style
import style from './DragDropUploader.module.css';

const DragDropUploaderHeader = ({ label, onClickCancelDragDrop, isFetching, isZetti }: IDragDropUploaderHeader) => {
  return (
    <div className={style['container-text']}>
      <div className={style['flex-container']}>
        <p className={style['label-text']}>{label}</p>
      </div>
    {
      !!isZetti || !!isFetching || (

      <div className={style['cancel-button']}>
        <IconButton
          size='xs'
          className={style['icon-button-close']}
          icon={<CloseIcon />}
          onClick={onClickCancelDragDrop}
        />
      </div>
      )
    }
    </div>
  );
};

export default DragDropUploaderHeader;
