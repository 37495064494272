// handler
import { handlerDownloadErrors } from './handler';
// helpers
import { traslateToSpanishPossibleErrors } from './helpers';
// rsuite
import { ButtonToolbar, Button, Loader } from 'rsuite';
// style
import style from './DragDropWithErrors.module.css';
// mui v5
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { EX5057 } from 'config/flags';
import { useUser } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { maxProducts } from 'utils/constants';

const DragDropWithErrors = ({ errors, onHandlerClick, isLoadingFileWithErrors }) => {
    // translation by Country
    const { t } = useTranslation();
    const { userLocaleCountryCode, user } = useUser({});
    const EX6141 = user?.EX6141;
    if (EX5057) useChangeLanguage(userLocaleCountryCode);
    // helpers
    const spanishErrorText = traslateToSpanishPossibleErrors(errors);
    const validateLimitProducts = errors?.errors?.some((error) => error === "EXCEEDED_PRODUCTS");

    const blockButton = errors?.invalid_lines === errors?.requested_products;
    const exceededLines = errors?.exceeded_lines ?? 0;
    const invalidLines = errors?.invalid_lines + exceededLines; 

    const messageError = () => {
        const errorsQuantity = errors?.errors?.length;

        if (errorsQuantity > 1) {
            return validateLimitProducts 
                ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNineteen', { productsExceeded: maxProducts })
                : t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNine');
        }
    
        if (errorsQuantity === 1 && validateLimitProducts) {
            return t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadEightteen', { productsExceeded: maxProducts });
        }
        else
        {
            return t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNine');
        }
    }

    return (
        <div className={style['container']}>
            <ErrorOutlineOutlinedIcon className={style['warning-icon']} />
            <p className={style['title-warning']}>
            {EX6141 ? messageError() : t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNine')}
            </p>
            <div className={style['container-list-errors']}>
                <p className={style['subtitle']}>
                    {EX5057
                        ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTen', {
                              invalidLines: invalidLines,
                              requestedProductsQuantity: errors?.requested_products,
                          })
                        : `Detectamos ${errors?.invalid_lines + errors?.exceeded_lines} de ${errors?.requested_products} productos con los <br />{' '}
                    siguientes errores:`}
                </p>
                <div className={style['list-errors']}>
                    <ul>
                        {spanishErrorText?.map((item) => {
                            return <li>{item}</li>;
                        })}
                    </ul>
                </div>

                <div
                    className={`link__download-errors-1 ${style['download-container']}`}
                    onClick={(evt) => {
                        evt.stopPropagation(), handlerDownloadErrors(errors?.errors_file_url);
                    }}
                >
                    <FileDownloadOutlinedIcon className="link__download-errors-1" />
                    <p className="link__download-errors-1">
                        {EX5057
                            ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadFifteen')
                            : 'Descargar errores'}
                    </p>
                </div>
                {EX6141 && validateLimitProducts && (
                    <>
                        <div
                            className={`link__download-errors-1 ${style['download-container']}`}
                            onClick={(evt) => {
                                evt.stopPropagation(), handlerDownloadErrors(errors?.errors_file_url);
                            }}
                        >
                            <FileDownloadOutlinedIcon className="link__download-errors-1" />
                            <p className="link__download-errors-1">{t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwenty')}</p>
                        </div>
                    </>
                )}
            </div>
            <div className={style['container-buttons']}>
                <ButtonToolbar className={style['buttons-toolbar']}>
                    <Button
                        className={
                            !!blockButton
                                ? `button__upload-new-file-1 ${style['button-action']}`
                                : `button__move-forward-1 ${style['button-action']}`
                        }
                        block
                        onClick={(evt) => {
                            blockButton ? '' : onHandlerClick(evt);
                        }}
                        disabled={!!isLoadingFileWithErrors}
                    >
                        {!!blockButton ? (
                            <span className="button__upload-new-file-1">
                                {EX5057
                                    ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadSixteen')
                                    : 'Subir archivo nuevo'}
                            </span>
                        ) : (
                            <span className="button__move-forward-1">
                                {!!isLoadingFileWithErrors ? <Loader /> : t('uploadFile.labelFinishOrder')}
                            </span>
                        )}
                    </Button>
                    {blockButton || (
                        <Button
                            className={`button__upload-new-file-2 ${style['button-action-secondary']}`}
                            block
                            //onClick={(evt) => evt.stopPropagation()}
                        >
                            <span className="button__upload-new-file-2">
                                {EX5057
                                    ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadSixteen')
                                    : 'Subir archivo nuevo'}
                            </span>
                        </Button>
                    )}
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default DragDropWithErrors;
