import { memo } from 'react';
import { t } from 'i18next';
// contexts
import { useDrugManufacturersContext, useMinimunPurchaseContext } from 'context';
// components
import { Button, Modal } from 'rsuite';
// utils && hooks
import { drugManufacturersString } from '@commons/modules/TableProductComparisonV2/utils/drugManufacturersString';
import { useUser } from 'utils/hooks';
// style
import style from './ModalMinimunAmount.module.css';

export const ModalMinimunAmount = memo(() => {
    const { user, userLocaleCountryCode } = useUser({});
    const { drugManufacturer } = useDrugManufacturersContext();
    const { showModalMinimunPurchase, handlerModalMinimunPurchase, minimunPurchase } = useMinimunPurchaseContext();

    const drugManufacturers = drugManufacturersString(userLocaleCountryCode, user, minimunPurchase, drugManufacturer);

    return (
        <Modal open={showModalMinimunPurchase} className={style['modal']}>
            <Modal.Header closeButton={false} className={style['modal-header']}>
                <p className={style['title-modal']}>{t('minimumAmount.min3')}</p>
            </Modal.Header>
            <Modal.Body className={style['body-modal']}>
                <p className={style['subtitle-modal']}>
                    {t('minimumAmount.min4', { drugManufacturers: drugManufacturers })}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    block
                    className={`button__minimum-purchase-modify ${style['action-button-modal']}`}
                    onClick={() => handlerModalMinimunPurchase()}
                >
                    <span className={style['text-button-active']}>{t('minimumAmount.min5')}</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
});
