import { Whisper, Tooltip } from 'rsuite';
import styles from './RsuiteTooltip.module.css';

const RsuiteTooltip = ({
    placement,
    text,
    trigger,
    children,
    minWidth = 'max-content',
    disabled = false,
    marginTop = 0,
    padding = '',
    width = '',
    fontSize = 16,
    parentRef = null,
    textAlign = null,
    customStyle = {},
}) => {
    return (
        <Whisper
            ref={parentRef}
            trigger={trigger}
            placement={placement}
            controlId={`control-id-${text}`}
            speaker={
                <Tooltip
                    className={styles['root']}
                    style={{
                        marginTop: marginTop,
                        minWidth: minWidth,
                        textAlign: textAlign || 'center',
                        padding: padding,
                        width: width,
                        fontSize: fontSize,
                        ...customStyle,
                    }}
                >
                    {text}
                </Tooltip>
            }
            disabled={disabled}
        >
            {children}
        </Whisper>
    );
};

export default RsuiteTooltip;
