import { useState } from 'react';
// components
import { DragDropUploaderHeader, DragDropWithErrors, LoaderDragDropFile, MessagesDragDropUploader } from '.';
// interface
import { IDragDropUploader } from './DragDropUploader.interface';

// rsuite
import { Panel, Uploader } from 'rsuite';

// style
import { EX4135, EX4380, EX5057 } from 'config/flags';
import { NewUseCart } from 'context';
import { useStatusContext } from 'context/control-state';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import style from './DragDropUploader.module.css';
import { CartHaveProductsAlert } from './MessagesDragDropUploader/CartHaveProductsAlert';

const DragDropUploader = ({
    onClickCancelDragDrop,
    handleUpload,
    isDragging,
    handlerDrop,
    blockerFormat,
    errors,
    dataWithError,
    isFetching,
    formatFile,
    handlerUploadWithErrors,
    isZetti,
    isLoadingFileWithErrors
}: IDragDropUploader) => {
    const { t } = useTranslation();
    const { quantityCart } = NewUseCart();
    const { setDisplayModalOnBoarding, displayModalOnBoarding } = useStatusContext();
    const { userLocaleCountryCode } = useUser({});
    useChangeLanguage(userLocaleCountryCode);
    // state
    const [files, setFiles] = useState<any>([]);

    return (
        <div className={style['container-panel-uploader']}>
            <Panel
                header={
                    <DragDropUploaderHeader
                        onClickCancelDragDrop={onClickCancelDragDrop}
                        label={
                            isFetching
                                ? EX5057
                                    ? t('uploadFile.labelAnalyzingFile')
                                    : 'Estamos analizando el archivo'
                                : errors
                                ? ''
                                : t('shoppingCart.shoppingCart_TwentySeven')
                        }
                        isFetching={isFetching}
                        isZetti={isZetti}
                    />
                }
                bordered
            >
                <Uploader
                    name="file"
                    accept={!!EX4380 ? 'text/plain/*' : '.res, .fal_, .fal, .xls, .xlsx, .txt, text/plain/*'}
                    multiple={false}
                    autoUpload={true}
                    fileListVisible={false}
                    action=""
                    draggable
                    onUpload={(file) => {
                        handleUpload(file.blobFile), setFiles(file);
                    }}
                    // onDrop={handlerDrop}
                    disabled={isFetching}
                >
                    <div
                        style={{
                            minHeight: '50vh',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderColor: !!blockerFormat || !!errors ? '#E22E2E' : '#0171E6',
                            backgroundColor: isFetching
                                ? '#EDF6FF'
                                : errors
                                ? '#FFEBEB'
                                : !!blockerFormat
                                ? '#FFEBEB'
                                : isDragging
                                ? '#EDF6FF'
                                : '#FFF',
                            cursor: 'pointer',
                        }}
                    >
                        {isFetching ? (
                            <LoaderDragDropFile filename={formatFile} />
                        ) : blockerFormat ? (
                            <MessagesDragDropUploader
                                text={t('shoppingCart.shoppingCart_TwentyFive')}
                                subtitle={
                                    EX5057
                                        ? t('uploadFile.labelErrorExtensionAllowed')
                                        : 'Asegurate que el formato de archivo que estas intentando subir sea el correcto.'
                                }
                                src="/assets/images/cancel.svg"
                                blockerFormat={blockerFormat}
                                flag={false}
                            />
                        ) : errors ? (
                            <DragDropWithErrors
                            isLoadingFileWithErrors={isLoadingFileWithErrors}
                                errors={dataWithError}
                                onHandlerClick={(evt) => {
                                    evt.stopPropagation(), handlerUploadWithErrors(files.blobFile);
                                }}
                            />
                        ) : isDragging ? (
                            <>
                                {displayModalOnBoarding && setDisplayModalOnBoarding(false)}
                                {quantityCart() ? <CartHaveProductsAlert /> : null}

                                <MessagesDragDropUploader
                                    text={
                                        EX4135
                                            ? t(
                                                  'efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyTwo',
                                              )
                                            : 'Soltá tu archivo para iniciar una compra eficiente.'
                                    }
                                    //linkText='o Subí un archivo'
                                    // src='/assets/images/cloud2.svg'
                                    blockerFormat={blockerFormat}
                                    flag={false}
                                />
                            </>
                        ) : (
                            <>
                                {displayModalOnBoarding && setDisplayModalOnBoarding(false)}

                                {quantityCart() ? <CartHaveProductsAlert /> : null}

                                <MessagesDragDropUploader
                                    text={t('shoppingCart.shoppingCart_TwentySix')}
                                    //linkText='o Subí un archivo'
                                    src="/assets/images/cloud2.svg"
                                    // icon={<CloudUploadIcon className={style['icon-cloud']} />}
                                    blockerFormat={blockerFormat}
                                    flag={true}
                                />
                            </>
                        )}
                    </div>
                </Uploader>
            </Panel>
        </div>
    );
};

export default DragDropUploader;
