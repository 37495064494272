import React from 'react';
import { Button, Modal } from 'rsuite';
import styles from './ModalConfirmationDrugOff.module.css';
import { ButtonGhost } from '@commons/components/Button';
import { IModalConfirmationDrugOff } from './ModalConfirmationDrugOff.interface';

export const ModalConfirmationDrugOff = ({
    open = false,
    handleClose,
    handleConfirm,
    backdrop,
    title,
    body,
    classNameTitle,
    btnLabel,
    reference
}: IModalConfirmationDrugOff) => {
    return (
        <Modal ref={reference} className={styles['wrapper']} backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
            <Modal.Header className={styles['header']}>
                <Modal.Title className={classNameTitle}>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={styles['body']}>
                <p>{body}</p>
            </Modal.Body>
            <Modal.Footer className={styles['buttons']}>
                <ButtonGhost onClick={handleClose} text={<span>Cancelar</span>} height={48} width={''} />
                <Button
                    className="button__empty-cart"
                    onClick={() => handleConfirm()}
                    appearance="primary"
                >
                    <span className="button__empty-cart">{btnLabel}</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
