import axios from 'axios';

export const handlerDownloadErrors = async (errorsFileUrl) => {
  let fileName = errorsFileUrl.split('/').slice(-1);

  await axios.get(errorsFileUrl, { responseType: 'blob' }).then((response) => {
    const blob = new Blob([response.data], { type: 'application/text' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName.toString();
    link.click();
    link.remove();
  });
};
