import React from 'react';
import { Button, Modal } from 'rsuite';
import styles from './ModalConfirmation.module.css';
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';
import { ButtonGhost } from '@commons/components/Button';

export const ModalConfirmation = ({
    open,
    handleClose,
    handleConfirm,
    backdrop,
    title = 'Vaciar carrito',
    body = 'Se eliminarán todos los productos del carrito.',
    btnLabel = '',
    stylesClassName = ''
}) => {
    // translation
    const { t } = useTranslation();
    return (
        <Modal className={styles['wrapper']} backdrop={backdrop} keyboard={false} open={open} onClose={handleClose}>
            <Modal.Header className={styles['header']}>
                <Modal.Title className={stylesClassName}>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body className={styles['body']}>
                <p>{body}</p>
            </Modal.Body>
            <Modal.Footer className={styles['buttons']}>
                <Button
                    className="button__empty-cart"
                    onClick={() => handleConfirm() && handleClose()}
                    appearance="primary"
                >
                    {btnLabel ? (
                        <span className="button__empty-cart">{btnLabel}</span>
                    ) : (
                        <span className="button__empty-cart">
                            {!!EX5057 ? t('shoppingCart.labelEmptyCart') : 'Si, vaciar carrito'}
                        </span>
                    )}
                </Button>
                <ButtonGhost onClick={handleClose} text={<span>Cancelar</span>} height={48} width={'100%'} />
            </Modal.Footer>
        </Modal>
    );
};
