import useChangeLanguage from 'utils/hooks/use-change-language';
import style from './CartHaveProductsAlerts.module.css';
import { exclamationMark } from './Icons';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import { EX4135 } from 'config/flags';

function CartHaveProductsAlert() {
    const { t } = useTranslation();
    const { userLocaleCountryCode } = useUser({});
    if (EX4135) useChangeLanguage(userLocaleCountryCode);
    return (
        <div className={style['container']}>
            {exclamationMark}
            <span className={style['text']}>
                {EX4135
                    ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyOne')
                    : 'Se van a añadir a la comparativa los productos que tenés en el carrito.'}
            </span>
        </div>
    );
}

export default CartHaveProductsAlert;
