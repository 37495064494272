import { t } from 'i18next';
// context
import { useDragAndDrop } from 'context/dragAndDropContext';
import { usePointOfSale } from 'context';
// rsuite
import { Button, ButtonToolbar } from 'rsuite';
// utils
import { isZettiFormat } from 'utils/pointOfSale';
// style
import styles from './ModalDontHaveCredentials.module.css';

const ModalDontHaveCredentials = ({ dontHaveCredentialsModal, setDontHaveCredentialsModal, clientId, posId }) => {
    const { pointOfSale } = usePointOfSale();

    const { setVisibleDragDrop, handleDragLeave } = useDragAndDrop();
    const handlerRedirect = () => {
        setDontHaveCredentialsModal(false);
        window.location.href = `/${clientId}/settings/POS/${posId}/posDetails`;
    };

    const abortConnected = () => {
        setDontHaveCredentialsModal(false);

        if (isZettiFormat(pointOfSale)) {
            handleDragLeave();
        } else {
            setVisibleDragDrop(false);
        }
    };

    return (
        <div
            className={styles['wrapper-card-container-fullblur']}
            style={{ display: dontHaveCredentialsModal ? 'flex' : 'none' }}
        >
            <div className={styles['container-card']}>
                <img src={'/assets/images/connect-credential.svg'} alt="IsologoHorizontal" className={styles['icon']} />
                <p className={styles['title']}>
                    {t('efficientPurchasing.comparative.efficientPurchasing_comparativeTwentyNine')}
                </p>
                <p className={styles['subtitle']}>{t('modals.credentialModal_NeedTwoDrugstores')}</p>
                <ButtonToolbar>
                    <Button
                        className={`button__do-it-later-1 ${styles['close-button']}`}
                        onClick={() => abortConnected()}
                    >
                        Lo hago más tarde
                    </Button>
                    <Button
                        className={`button__connect-drugstore-2 ${styles['config-button']}`}
                        onClick={handlerRedirect}
                    >
                        {t('modals.credentialModal_ConnectDrugstores')}
                    </Button>
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default ModalDontHaveCredentials;
