import { t } from 'i18next';

type Props = {
    value: string | number;
    shouldIncludeTax?: boolean;
    isoCode?: string;
    digitsValidation?: number;
};

const useCurrencyFormatter = () => {
    const includesDecimal = (value: string): boolean => {
        return value?.includes('.');
    };

    const formatCurrency = ({ value, isoCode, digitsValidation }: Props) => {
        const country = t('currency.country') === 'currency.country' ? 'es-AR' : t('currency.country');
        const countryIsoCode = t('currency.isoCode') === 'currency.isoCode' ? 'ARS' : t('currency.isoCode');
        let maximumFractionDigits = 2;

        /* #region code add by defined according to digitsValidation digits and without decimals*/
        if (digitsValidation) {
            const valueStr = value?.toString();
            const hasDecimal = digitsValidation ? includesDecimal(valueStr) : false;
            const valueFirstPart = hasDecimal ? valueStr?.split('.')[0] : valueStr;
            if (valueFirstPart?.length > digitsValidation) {
                maximumFractionDigits = 0;
            }
        }
        /* #endregion */

        const options = {
            style: 'currency',
            currency: isoCode || countryIsoCode,
            useGrouping: true,
            ...(digitsValidation && { maximumFractionDigits, roundingMode: 'floor' }),
        };

        const formatter = new Intl.NumberFormat(country, options);
        const formatted = formatter?.format(+value);
        const currencyParts = formatter?.formatToParts(+value);
        let mainInteger = '';
        let decimalSeparator = '';
        let cents = '';
        let currencySymbol = '';

        for (const part of currencyParts) {
            if (part?.type === 'integer' || part?.type === 'group') {
                mainInteger += part?.value;
            } else if (part?.type === 'decimal') {
                decimalSeparator = part?.value;
            } else if (part?.type === 'fraction') {
                cents = part?.value;
            } else if (part?.type === 'currency') {
                currencySymbol = part?.value;
            }
        }

        const detailedValues = {
            mainInteger,
            decimalSeparator,
            cents,
            currencySymbol,
        };

        const fullCurrency = {
            fullValue: formatted,
            taxLabel: t('currency.labelTax') === 'currency.taxLabel' ? '+ IVA' : t('currency.labelTax'),
            detailedValues,
            currencyParts,
            originalValue: value
        };

        return fullCurrency;
    };

    return {
        formatCurrency,
    };
};

export default useCurrencyFormatter;
