import React from 'react';
import { Client } from 'common/types';
interface ClientDetilsProps {
    children: React.ReactNode;
    client: Client;
    mutateClient(data?: any, shouldRevalidate?: boolean): Promise<any>;
    clientPos?: any;
    mutateClientPos(): Promise<any> | any;
    has_payment_pending?: boolean; 
    has_subscription?: boolean;
}
const ClientDetailsContext = React.createContext<Omit<ClientDetilsProps, 'children'>>(null);

export const ClientDetailsProvider = ({ children, ...props }: ClientDetilsProps) => {
    return <ClientDetailsContext.Provider value={{ ...props }}>{children}</ClientDetailsContext.Provider>;
};

export const useClientDetails = () => {
    const context = React.useContext(ClientDetailsContext);
    if (context === undefined) {
        throw new Error('useClientDetails must be used within a ClientDetailsProvider');
    }
    return context;
};
