import { DragEvent, useRef, useState } from "react";

const useDragAndDropEvents = () => {
    const [isDragging, setIsDragging] = useState(false);
    const dragCounter = useRef(0);

    const handleDragEnter = (e: DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current++;
        setIsDragging(true);
    };
    
    const handleDragLeave = (e: DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current--;
        if (dragCounter.current === 0) {
          setIsDragging(false);
        }
    };
    const handleDrop = (e: DragEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dragCounter.current = 0;
        setIsDragging(false);
    };

    return {handleDragEnter, handleDragLeave, handleDrop, isDragging}
}

export default useDragAndDropEvents;